import cloneDeep from 'lodash.clonedeep';

import { formatCurrency } from '@utils/text';
import { ContractTypeEnum } from '@utils/translators/proposal';
import { BidForFeedback, ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';

interface RefusedStringParams {
  totalBids: BidForFeedback[];
  bidRanking: number;
  traderBid: BidForFeedback;
  bestBidPrice: string;
}

const getRefusedString = ({ totalBids, bidRanking, traderBid, bestBidPrice }: RefusedStringParams): string => {
  const numBids = totalBids.length;
  const modality = traderBid.retailService ? 'Varejista' : 'Atacadista';
  return `Oi pessoal, tudo bem? Infelizmente não foi dessa vez :( O cliente optou seguir para a próxima rodada de cotação com outras fornecedoras de energia. Tivemos ${numBids} fornecedoras participantes nessa rodada e você ficou em ${bidRanking}º lugar.

A melhor curva de preços da rodada na modalidade, ${modality}, sem levar em consideração a comissão da Clarke, foi de:
${bestBidPrice}

É muito importante que a proposta seja o mais dentro do escopo requerido possível, isso faz diferença na sua avaliação e na decisão do cliente. Consideramos que a sua proposta tenha ficado fora do escopo pedido e isso influenciou o seu resultado.

Agradecemos a participação! Fiquem por perto que em breve abriremos novos processos de contratação e contamos com a sua proposta! Abraços, Clarke.`;
};

const getChosenString = ({ totalBids, bidRanking, traderBid, bestBidPrice }: RefusedStringParams): string => {
  const numBids = totalBids.length;
  const modality = traderBid.retailService ? 'Varejista' : 'Atacadista';
  const extraPositionString = traderBid.savings.isInsideScope
    ? `das propostas dentro do escopo (que atenderam às condições solicitadas pelo cliente) `
    : '';

  return `Oi pessoal, tudo bem? Temos boas notícias, vocês foram selecionados para a próxima rodada de cotação de energia! :) Tivemos ${numBids} fornecedoras participantes nessa rodada e ${extraPositionString}vocês ficaram em ${bidRanking}º lugar. A melhor curva de preço da rodada na modalidade, ${modality}, sem levar em consideração a comissão da Clarke, foi de:
${bestBidPrice}

Lembrando que é muito importante que a proposta esteja o mais dentro do escopo requerido possível, isso faz diferença na sua avaliação e na decisão do cliente. Caprichem na proposta e boa sorte nessa etapa! Abraços, Clarke!`;
};

const sortAllBidsByTotalSavings = (
  allBids: BidForFeedback[],
  contractType: keyof typeof ContractTypeEnum,
): BidForFeedback[] => {
  return cloneDeep(allBids).sort((a, b) => {
    const bTotalSavings =
      (contractType === 'FIXED_PRICE'
        ? b.savings.fixedPrice?.groupReport.totalCurrencyAmount
        : b.savings.guaranteedSaving?.groupReport.totalCurrencyAmount) || NaN;
    const aTotalSavings =
      (contractType === 'FIXED_PRICE'
        ? a.savings.fixedPrice?.groupReport.totalCurrencyAmount
        : a.savings.guaranteedSaving?.groupReport.totalCurrencyAmount) || NaN;
    return bTotalSavings - aTotalSavings;
  });
};

const getTraderIndexPosition = (traderId: string, allBids: BidForFeedback[]): number => {
  const overallIndex = allBids.findIndex((bid) => bid.trader.id === traderId);
  const traderBid = allBids[overallIndex];

  if (traderBid.savings.isInsideScope) {
    const bidsInsideScope = allBids.filter((bid) => bid.savings.isInsideScope);
    return bidsInsideScope.findIndex((bid) => bid.id === traderBid.id);
  }

  return overallIndex;
};

const getBestBidPrice = (traderBid: BidForFeedback, allBids: BidForFeedback[]): string => {
  const targetBids = allBids.filter((bid) => bid.retailService == traderBid.retailService);

  let bestBid: BidForFeedback = targetBids[0];
  let greatherAmmount = 0;

  for (const bid of targetBids) {
    const bidType = bid.contractType === 'FIXED_PRICE' ? bid.savings.fixedPrice : bid.savings.guaranteedSaving;
    if (bidType && bidType.groupReport.totalCurrencyAmount > greatherAmmount) {
      greatherAmmount = bidType.groupReport.totalCurrencyAmount;
      bestBid = bid;
    }
  }

  return bestBid.economy[0].economyPerYear.map(({ year, amount }) => `${year}: ${formatCurrency(amount)}`).join(' | ');
};

export const getRefusedFeedbackTemplate = (traderId: string, proposal: ProposalForFeedback): string => {
  const sortedBids = sortAllBidsByTotalSavings(proposal.bids, proposal.contractType);
  const thisTraderIndex = sortedBids.findIndex((bid) => bid.trader.id === traderId);
  const traderBid = sortedBids[thisTraderIndex];
  const traderIndexPosition = getTraderIndexPosition(traderId, sortedBids);
  const bestBidPrice = getBestBidPrice(traderBid, sortedBids);

  return getRefusedString({
    totalBids: sortedBids,
    bidRanking: traderIndexPosition + 1,
    traderBid,
    bestBidPrice,
  });
};

export const getChosenFeedbackTemplate = (traderId: string, proposal: ProposalForFeedback): string => {
  const sortedBids = sortAllBidsByTotalSavings(proposal.bids, proposal.contractType);
  const thisTraderIndex = sortedBids.findIndex((bid) => bid.trader.id === traderId);
  const traderBid = sortedBids[thisTraderIndex];
  const traderIndexPosition = getTraderIndexPosition(traderId, sortedBids);
  const bestBidPrice = getBestBidPrice(traderBid, sortedBids);

  return getChosenString({
    totalBids: sortedBids,
    bidRanking: traderIndexPosition + 1,
    traderBid,
    bestBidPrice,
  });
};
