import React from 'react';

import { useQuery } from '@apollo/client';
import { formatDateFromString, parseStringToDate } from '@utils/text';
import { DocumentTypeEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';
import { Unpacked } from '@utils/types';

import { GET_PROCESS_BY_PROPOSAL_ID, QUERY_SAVINGS_FOR_SINGLE_PROPOSAL } from './queries';

import {
  BidSavingsGraphQLResponse,
  BidWithSavings,
  CommercialGroup,
  InputPrice,
  Proposal,
  Savings,
  SavingsCalculationResult,
  UnitReport,
  GroupReport,
  TraderLogo,
  ProcessGraphQLResponse,
} from './types';

type SavingsCalculationResultInput = Pick<
  Unpacked<BidSavingsGraphQLResponse['proposal']['bids']>['savings'],
  'fixedPrice' | 'guaranteedSaving'
>;
const parseSavingsCalculationResult = (
  savingType: keyof SavingsCalculationResultInput,
  input: SavingsCalculationResultInput,
): SavingsCalculationResult => {
  const result = input[savingType];
  const isResultAvailable = result !== null;

  const groupReport: GroupReport = {
    period: isResultAvailable ? result.groupReport.period : NaN,
    npv: isResultAvailable ? result.groupReport.npv : NaN,
    totalCurrencyAmount: isResultAvailable ? result.groupReport.totalCurrencyAmount : NaN,
    totalPercentageAmount: isResultAvailable ? result.groupReport.totalPercentageAmount : NaN,
    valuesPerYear: isResultAvailable
      ? result.groupReport.valuesPerYear.map(
          ({ year, energyPrice, acrCost, aclCost, management, totalSavingsAmount }) => ({
            year,
            energyPrice,
            aclCost,
            acrCost,
            management,
            totalSavingsAmount,
          }),
        )
      : [],
    initialExpenses: isResultAvailable ? result.groupReport.initialExpenses : NaN,
  };
  const inputPrices: InputPrice[] = isResultAvailable
    ? result.inputPrices.map(({ period, pricePerYear }) => ({
        period,
        pricePerYear: pricePerYear.map(({ year, value }) => ({ year, value })),
      }))
    : [];

  const unitsReports: { [name: string]: UnitReport } = {};

  if (isResultAvailable) {
    result.unitsReports.forEach(
      ({ name, period, totalCurrencyAmount, totalPercentageAmount, valuesPerYear, initialExpenses }) => {
        unitsReports[name] = {
          name,
          period,
          totalCurrencyAmount,
          totalPercentageAmount,
          valuesPerYear: valuesPerYear.map(
            ({ year, energyPrice, acrCost, aclCost, management, totalSavingsAmount }) => ({
              year,
              energyPrice,
              aclCost,
              acrCost,
              management,
              totalSavingsAmount,
            }),
          ),
          initialExpenses,
        };
      },
    );
  }
  return { groupReport, inputPrices, unitsReports };
};

const parseBidSavings = (savings: Unpacked<BidSavingsGraphQLResponse['proposal']['bids']>['savings']): Savings => {
  const { status, isInsideScope, retailCommission, wholesaleCommission, ...rest } = savings;

  return {
    status,
    isInsideScope,
    retailCommission: retailCommission === null ? 0 : retailCommission,
    wholesaleCommission: wholesaleCommission === null ? 0 : wholesaleCommission,
    fixedPrice: parseSavingsCalculationResult('fixedPrice', rest),
    guaranteedSaving: parseSavingsCalculationResult('guaranteedSaving', rest),
  };
};

const getTraderLogUri = (documents: TraderLogo[]): string => {
  return documents.filter(({ docType }) => DocumentTypeEnum[docType] === DocumentTypeEnum.LOGO_IMAGE)[0].uri || '';
};

export function useGetBidSavingsForProposal(
  proposalId: string,
): [BidWithSavings[], boolean, CommercialGroup, Proposal, string | null] {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [savings, setSavings] = React.useState<BidWithSavings[]>([]);
  const [processName, setProcessName] = React.useState<string | null>('');
  const [group, setGroup] = React.useState<CommercialGroup>({
    id: '',
    name: '',
    legalName: '',
    docType: null,
    docNumber: '',
  });
  const [proposal, setProposal] = React.useState<Proposal>({
    id: '',
    round: NaN,
    units: [],
    startDate: '',
    lowerFlexibility: NaN,
    upperFlexibility: NaN,
    guaranteeType: 'NO_GUARANTEE',
    periods: [],
    commissionModality: 'RETAILER',
  });

  useQuery<BidSavingsGraphQLResponse, { proposalId: string }>(QUERY_SAVINGS_FOR_SINGLE_PROPOSAL, {
    fetchPolicy: 'network-only',
    variables: { proposalId },
    onCompleted: (data) => {
      const rawBids = data.proposal.bids;
      const rawGroup = data.proposal.group;
      const rawProposal = data.proposal;

      const proposalResult: Proposal = {
        id: rawProposal.id,
        round: rawProposal.round,
        guaranteeType:
          rawProposal.guaranteeType === null
            ? 'NO_GUARANTEE'
            : (rawProposal.guaranteeType as keyof typeof GuaranteeTypeEnum),
        periods: [...rawProposal.periods],

        lowerFlexibility: rawProposal.lowerFlexibility,
        upperFlexibility: rawProposal.upperFlexibility,
        commissionModality: rawProposal.commissionModality,
        startDate: formatDateFromString(rawProposal.createdAt.split(' ')[0], 'YYYY-MM-DD', 'DD/MM/YYYY'),
        units: rawProposal.supply.map(({ unit }) => ({ id: unit.id, name: unit.name })),
      };
      setProposal(proposalResult);

      const groupResult: CommercialGroup = {
        id: rawGroup.id,
        name: rawGroup.name,
        legalName: rawGroup.legalName,
        docNumber: rawGroup.docNumber,
        docType: rawGroup.docType,
      };
      setGroup(groupResult);

      const proposalType = data.proposal.proposalType;
      const bidsResult: BidWithSavings[] = rawBids.map((rawBid) => {
        const {
          id,
          deadline,
          lowerFlexibility,
          upperFlexibility,
          guaranteeType,
          contractType,
          trader,
          periods,
          economy,
          retailService,
          coverCceeTaxes,
        } = rawBid;

        return {
          id,
          deadline: parseStringToDate(deadline),
          lowerFlexibility,
          upperFlexibility,
          proposalType,
          guaranteeType: guaranteeType === null ? 'NO_GUARANTEE' : (guaranteeType as keyof typeof GuaranteeTypeEnum),
          contractType,
          coverCceeTaxes,
          trader: {
            id: trader.id,
            name: trader.name,
            score: trader?.score || 0,
            logoUri: trader.documents.length ? getTraderLogUri(trader.documents) : '',
          },
          periods: [...periods],
          economy: economy.map((entry) => ({
            period: entry.period,
            economyPerYear: entry.economyPerYear.map(({ year, amount }) => ({ year, amount })),
          })),
          retailService,
          savings: parseBidSavings(rawBid.savings),
        };
      });
      setSavings(bidsResult);
      setIsLoading(false);
    },
    onError: () => {
      setSavings([]);
      setIsLoading(false);
    },
  });
  useQuery<ProcessGraphQLResponse, { proposalId: string }>(GET_PROCESS_BY_PROPOSAL_ID, {
    fetchPolicy: 'network-only',
    variables: { proposalId },
    onCompleted: (data) => {
      const rawProcess = data.getProcessByProposalId.process;
      setProcessName(rawProcess.name);

      setIsLoading(false);
    },
    onError: () => {
      setProcessName(null);
      setIsLoading(false);
    },
  });
  return [savings, isLoading, group, proposal, processName];
}
