import React from 'react';

import EmptyNonFinalBidMessageDisplay from '@components/atoms/proposal-round/empty-non-final-bid-message';
import BidFeedbackMessageDisplay from '@components/atoms/proposal-round/bid-feedback-message';
import UploadedFileDisplay from '@components/atoms/proposal-round/uploaded-file-display';

import {
  ContractTypeEnum,
  CoverCeeeCostEnum,
  CoverCeeeTaxesEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
} from '@utils/translators/proposal';
import { formatCurrency } from '@utils/text';

import { Bid } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const BidEconomySection: React.FC<{ bid: Bid }> = ({ bid }) => {
  const isFixedPrice = bid.contractType === 'FIXED_PRICE';
  const titlePrefix = isFixedPrice ? 'Preço fixo' : 'Desconto Garantido';
  return (
    <>
      {bid.economy.map(({ period, economyPerYear }, index) => (
        <React.Fragment key={index}>
          <h2 className="font-medium text-paragraph-medium">{`${titlePrefix} em ${period} anos`}</h2>
          <div className="flex overflow-x-auto space-x-3 sm:space-x-6">
            {economyPerYear.map(({ year, amount }, pearYearIndex) => (
              <div key={pearYearIndex} className="flex flex-col">
                <p className="text-paragraph-small text-neutral-50">{year}</p>
                <p className="text-paragraph-medium">{isFixedPrice ? formatCurrency(amount) : `${amount}%`}</p>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

interface NonFinalBidContentsDisplayProps {
  bid: Bid | null;
}

const NonFinalBidContentsDisplay: React.FC<NonFinalBidContentsDisplayProps> = ({ bid }) => {
  if (!bid) return <EmptyNonFinalBidMessageDisplay />;

  return (
    <div className="flex flex-col py-2 px-3 space-y-3 sm:px-5">
      <div className="flex flex-col space-y-2">
        <h2 className="font-medium text-paragraph-medium">Proposta de Economia</h2>
        <div className="space-y-2 sm:flex">
          <div className="flex flex-col sm:w-2/4 lg:w-1/4">
            <p className="text-paragraph-small text-neutral-50">Tipo de contrato</p>
            <p className="text-paragraph-medium">{ContractTypeEnum[bid.contractType]}</p>
          </div>
          <div className="flex flex-col sm:w-2/4 lg:w-1/4">
            <p className="text-paragraph-small text-neutral-50">Durações do contrato</p>
            <p className="text-paragraph-medium">{bid.periods.map((year) => `${year} anos`).join(', ')}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <BidEconomySection bid={bid} />
      </div>
      <div className="flex flex-col space-y-2">
        <h2 className="font-medium text-paragraph-medium">Condições comerciais</h2>
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Tipo de proposta</p>
            <p className="text-paragraph-medium">{ProposalTypeEnum[bid.proposalType]}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Flexibilidade inferior</p>
            <p className="text-paragraph-medium">{`-${bid.lowerFlexibility}%`}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Flexibilidade superior</p>
            <p className="text-paragraph-medium">{`+${bid.upperFlexibility}%`}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Varejista</p>
            <p className="text-paragraph-medium">{bid.retailService ? 'Sim' : 'Não'}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Cobertura da taxa de adesão da CCEE</p>
            <p className="text-paragraph-medium">{CoverCeeeCostEnum[bid.coverCceeCost]}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Cobertura de Encargos da CCEE</p>
            <p className="text-paragraph-medium">{CoverCeeeTaxesEnum[bid.coverCceeTaxes]}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Garantia</p>
            <p className="text-paragraph-medium">{GuaranteeTypeEnum[bid.guaranteeType]}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Meses de faturamento</p>
            <p className="text-paragraph-medium">
              {isNaN(bid.guaranteeMonths)
                ? 'Não'
                : `${bid.guaranteeMonths} ${bid.guaranteeMonths === 1 ? 'mês' : 'meses'}`}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Dia do pagamento</p>
            <p className="text-paragraph-medium">{`${bid.payDay}º dia útil`}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Cobertura da taxa de Adequação SMF</p>
            <p className="text-paragraph-medium">{bid.coverMeterSystem ? 'Sim' : 'Não'}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Data criação</p>
            <p className="text-paragraph-medium">
              {new Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
              }).format(bid.createdAt)}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-paragraph-small text-neutral-50">Validade da proposta</p>
            <p className="text-paragraph-medium">
              {new Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
              }).format(bid.deadline)}
            </p>
          </div>
        </div>
        <div className="flex flex-col py-4 space-y-4">
          <div className="flex space-x-4">
            <p className="text-paragraph-medium text-neutral-50">Comissão Clarke</p>
            <p className="text-paragraph-medium">{bid.clarkeCommissionNote}</p>
          </div>
          {bid.documents.length > 0 && (
            <div className="flex space-x-8">
              <p className="text-paragraph-medium text-neutral-50">Documentos</p>
              {bid.documents.map((document, index) => (
                <div className="flex flex-col space-y-2 w-full" key={`${document.entityId}-${index}`}>
                  <UploadedFileDisplay file={document} />
                </div>
              ))}
            </div>
          )}
        </div>
        <BidFeedbackMessageDisplay feedback={bid.feedback} />
      </div>
    </div>
  );
};

export default NonFinalBidContentsDisplay;
