import { gql } from '@apollo/client';

export const GET_BID_DATA_FOR_FEEDBACK = gql`
  query GetDataForFeedbacks($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      round
      bids {
        id
        contractType
        retailService
        economy {
          period
          economyPerYear {
            year
            amount
          }
        }
        trader {
          id
          name
        }
        savings {
          status
          isInsideScope
          fixedPrice {
            groupReport {
              totalCurrencyAmount
            }
          }
          guaranteedSaving {
            groupReport {
              totalPercentageAmount
            }
          }
        }
      }
    }
  }
`;
