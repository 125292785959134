import { useMutation } from '@apollo/client';

import { BidFormData } from '@components/molecules/create-bid/types';
import { Bid, BidStatus } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { createCustomerDocument } from '@services/rest/create-documents';

import { ServerFaultError, ServerValidationError } from '@utils/errors';
import { parseStringToDate } from '@utils/text';
import { formatDateIntoLocalISOString } from '@utils/translators/date';
import { ClarkeCommissionTypeEnum } from '@utils/translators/proposal';

import {
  CreateBidGraphQLInput,
  DocumentTypeForBid,
  UpdateBidGraphQLInput,
  UpsertBidGraphQLResponse,
  UPSERT_BID_MUTATION,
} from './mutations';

const buildEconomyArray = (bid: BidFormData): Bid['economy'] => {
  const values = bid.productAndPrices.prices.values;
  const period = bid.productAndPrices.prices.duration;

  return [{ period, economyPerYear: values }];
};

const parseBidIntoGraphQLCreateInput = (
  bid: BidForCreation,
  proposalId: string,
): CreateBidGraphQLInput['upsertBidInput'] => {
  const { trader, status, deadline, guaranteeType, coverCceeTaxes, ...restBid } = bid;

  const otherGuaranteeType = guaranteeType === 'OTHER' ? bid.otherGuaranteeType : null;

  const meterSystemAmount = bid.coverMeterSystem ? bid.meterSystemAmount : null;

  const input: CreateBidGraphQLInput['upsertBidInput'] = {
    ...restBid,
    proposalId,
    traderId: trader.id,
    status: BidStatus[status],
    deadline: formatDateIntoLocalISOString(deadline),
    otherGuaranteeType,
    meterSystemAmount,
    coverCceeTaxes: coverCceeTaxes === 'FULL',
  };
  input.guaranteeType = guaranteeType !== 'NO_GUARANTEE' ? guaranteeType : undefined;
  return input;
};

type BidForCreation = Omit<
  Bid,
  'id' | 'trader' | 'createdAt' | 'commissionModality' | 'proposalType' | 'documents' | 'feedback'
> & {
  trader: { id: string };
};

export const extractBidForCreation = (bid: Bid, newStatus: Bid['status'], newDeadline: Date): BidForCreation => {
  const { id, createdAt, documents, feedback, proposalType, status, deadline, ...bidForCreation } = bid;
  return { ...bidForCreation, status: newStatus, deadline: newDeadline };
};

export const parseBidFormDataIntoBidForCreation = (
  bid: BidFormData,
  trader: Omit<Bid['trader'], 'name'>,
  status: keyof typeof BidStatus,
): BidForCreation => {
  const { commercialConditions, productAndPrices } = bid;
  return {
    trader,
    status,
    contractType: productAndPrices.contractType,
    note: commercialConditions.note,
    clarkeCommissionNote:
      ClarkeCommissionTypeEnum[commercialConditions.clarkeCommissionType as keyof typeof ClarkeCommissionTypeEnum],
    guaranteeType: commercialConditions.guaranteeType,
    otherGuaranteeType: commercialConditions.otherKindGuarantee === '' ? null : commercialConditions.otherKindGuarantee,
    guaranteeMonths: commercialConditions.guaranteeMonths === '' ? NaN : parseInt(commercialConditions.guaranteeMonths),
    retailService: commercialConditions.traderType === 'RETAIL',
    coverMeterSystem: commercialConditions.coverMeterSystem === 'YES',
    meterSystemAmount: commercialConditions.coverMeterSystem === 'YES' ? commercialConditions.meterSystemAmount : NaN,
    coverCceeCost: commercialConditions.coverCceeCost,
    coverCceeTaxes: commercialConditions.coverCceeTaxes,
    periods: [productAndPrices.prices.duration] as Bid['periods'],
    deadline: parseStringToDate(`${commercialConditions.bidDeadline.date} ${commercialConditions.bidDeadline.time}:00`),
    lowerFlexibility: commercialConditions.lowerFlexibility,
    upperFlexibility: commercialConditions.upperFlexibility,
    payDay: commercialConditions.payDay,
    economy: buildEconomyArray(bid),
  };
};

export function useCreateBid() {
  const [mutationFunction] = useMutation<UpsertBidGraphQLResponse, CreateBidGraphQLInput>(UPSERT_BID_MUTATION);

  const createBid = async (bid: BidForCreation, proposalId: string) => {
    const input = parseBidIntoGraphQLCreateInput(bid, proposalId);

    const { data, errors } = await mutationFunction({ variables: { upsertBidInput: input } });

    if (errors !== undefined || !data) throw new ServerFaultError('Houve um problema técnico com o servidor.');

    if (data.upsertBid.error) throw new ServerValidationError(data.upsertBid.error);

    const result = data.upsertBid;
    return { id: result.bid.id };
  };

  return createBid;
}

export function useCreateBidDocument() {
  return async (
    file: File,
    bidId: string,
    authToken: string,
    docType: DocumentTypeForBid = DocumentTypeForBid.OTHERS,
  ) => {
    const formData = new FormData();
    formData.append('label', `${file.name}`);
    formData.append('file', file);
    formData.append('entity_id', bidId);
    formData.append('entity', 'bid');
    formData.append('doc_type', `${docType}`);

    await createCustomerDocument(formData, authToken);
  };
}

type BidForUpdate = Omit<
  Bid,
  'commissionModality' | 'trader' | 'createdAt' | 'proposalType' | 'documents' | 'feedback'
> & {
  trader: { id: string };
};

const parseBidIntoGraphQLUpdateInput = (
  bid: BidForUpdate,
  proposalId: string,
): UpdateBidGraphQLInput['upsertBidInput'] => {
  const { trader, status, deadline, coverCceeTaxes, ...restBid } = bid;

  const guaranteeType = bid.guaranteeType !== 'NO_GUARANTEE' ? bid.guaranteeType : undefined;
  const otherGuaranteeType = guaranteeType === 'OTHER' ? bid.otherGuaranteeType : null;
  const meterSystemAmount = bid.coverMeterSystem ? bid.meterSystemAmount : null;

  const input = {
    ...restBid,
    proposalId,
    traderId: trader.id,
    status: BidStatus[status],
    deadline: formatDateIntoLocalISOString(deadline),
    guaranteeType,
    otherGuaranteeType,
    meterSystemAmount,
    coverCceeTaxes: coverCceeTaxes === 'FULL',
  };
  return input;
};

export const parseBidFormDataIntoBidForUpdate = (
  bidId: string,
  bid: BidFormData,
  trader: Omit<Bid['trader'], 'name'>,
  status: keyof typeof BidStatus,
): BidForUpdate => {
  const { commercialConditions, productAndPrices } = bid;
  return {
    id: bidId,
    trader,
    status,
    contractType: productAndPrices.contractType,
    note: commercialConditions.note,
    clarkeCommissionNote:
      ClarkeCommissionTypeEnum[commercialConditions.clarkeCommissionType as keyof typeof ClarkeCommissionTypeEnum],
    guaranteeType: commercialConditions.guaranteeType,
    otherGuaranteeType: commercialConditions.otherKindGuarantee === '' ? null : commercialConditions.otherKindGuarantee,
    guaranteeMonths: commercialConditions.guaranteeMonths === '' ? NaN : parseInt(commercialConditions.guaranteeMonths),
    retailService: commercialConditions.traderType === 'RETAIL',
    coverMeterSystem: commercialConditions.coverMeterSystem === 'YES',
    meterSystemAmount: commercialConditions.coverMeterSystem === 'YES' ? commercialConditions.meterSystemAmount : NaN,
    coverCceeCost: commercialConditions.coverCceeCost,
    coverCceeTaxes: commercialConditions.coverCceeTaxes,
    periods: [productAndPrices.prices.duration] as Bid['periods'],
    deadline: parseStringToDate(`${commercialConditions.bidDeadline.date} ${commercialConditions.bidDeadline.time}:00`),
    lowerFlexibility: commercialConditions.lowerFlexibility,
    upperFlexibility: commercialConditions.upperFlexibility,
    payDay: commercialConditions.payDay,
    economy: buildEconomyArray(bid),
  };
};

export function useUpdateBid() {
  const [mutationFunction] = useMutation<UpsertBidGraphQLResponse, UpdateBidGraphQLInput>(UPSERT_BID_MUTATION);

  const updateBid = async (bid: BidForUpdate, proposalId: string) => {
    const input = parseBidIntoGraphQLUpdateInput(bid, proposalId);
    const { data, errors } = await mutationFunction({ variables: { upsertBidInput: input } });

    if (errors !== undefined || !data) throw new ServerFaultError('Houve um problema técnico com o servidor.');

    if (data.upsertBid.error) throw new ServerValidationError(data.upsertBid.error);

    const result = data.upsertBid;
    return { id: result.bid.id };
  };

  return updateBid;
}
