import { DocType } from '@hooks/bids/savings/types';
import {
  CommissionModalityTypeEnum,
  CommissionTypeEnum,
  ContractTypeEnum,
  CoverCeeeCostEnum,
  CoverCeeeTaxesEnum,
  DocumentTypeEnum,
  EnergyTypeEnum,
  GuaranteeTypeEnum,
  ProductTypeEnum,
  ProposalTypeEnum,
  SubmarketEnum,
  TariffModeEnum,
  UnitTypeEnum,
} from '@utils/translators/proposal';

export interface CommissionAmount {
  amount: number;
  period: number;
}

export enum ProposalStatus {
  ACTIVE = 1,
  FINISHED = 2,
}

export enum BidStatus {
  ACTIVE = 1,
  CANCELED = 2,
  STAGE_WINNER = 3,
  STAGE_REFUSED = 4,
  FINAL_WINNER = 5,
  FINAL_REFUSED = 6,
  SIGNED = 7,
}

export type Subgroup = 'A2' | 'A3' | 'A3a' | 'A4' | 'AS';

export interface ConsumptionHistoryEntry {
  id: string;
  offPeak: number;
  peak: number;
  generator: number;
}

export interface UnitConsumptionHistory {
  january: ConsumptionHistoryEntry;
  february: ConsumptionHistoryEntry;
  march: ConsumptionHistoryEntry;
  april: ConsumptionHistoryEntry;
  may: ConsumptionHistoryEntry;
  june: ConsumptionHistoryEntry;
  july: ConsumptionHistoryEntry;
  august: ConsumptionHistoryEntry;
  september: ConsumptionHistoryEntry;
  october: ConsumptionHistoryEntry;
  november: ConsumptionHistoryEntry;
  december: ConsumptionHistoryEntry;
}

/** Unit with supply data */
export interface Unit {
  id: string;
  edc: { id: number; name: string; state: string };
  supply: { startDate: Date | null; endDate: Date | null };
  name: string;
  legalName: string;
  docType: 1 | 2;
  docNumber: string;
  unitType: keyof typeof UnitTypeEnum;
  subgroup: Subgroup;
  tariffMode: keyof typeof TariffModeEnum;
  totalConsumptionVolume: number;
  contractedDemandOffPeak: number;
  contractedDemandPeak: number;
  averageInvoiceAmount: number;
  hasGenerator: boolean;
  generatorCost: number;
  energyType: keyof typeof EnergyTypeEnum;
  submarket: keyof typeof SubmarketEnum | null;
  history: UnitConsumptionHistory;
  managementPrice?: number;
  initialExpenses?: number;
}

export interface UnitsResume {
  totalVolume: number;
  totalAmount: number;
  totalContractedDemandPeak: number;
  totalContractedDemandOffPeak: number;
}

export interface DocumentFile {
  id: string;
  entity: string;
  entityId: string;
  label: string;
  uri: string;
  docType: keyof typeof DocumentTypeEnum;
}

export interface EconomyPerYear {
  year: number;
  amount: number;
}

export interface Feedback {
  text: string;
  createdAt: Date;
}

export interface Bid {
  id: string;
  createdAt: Date;
  deadline: Date;
  status: keyof typeof BidStatus;
  trader: Trader;
  documents: DocumentFile[];
  contractType: keyof typeof ContractTypeEnum;
  periods: number[];
  economy: { period: number; economyPerYear: EconomyPerYear[] }[];
  proposalType: keyof typeof ProposalTypeEnum;
  commissionModality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
  lowerFlexibility: number;
  upperFlexibility: number;
  retailService: boolean;
  coverCceeCost: keyof typeof CoverCeeeCostEnum;
  coverCceeTaxes: keyof typeof CoverCeeeTaxesEnum;
  guaranteeType: keyof typeof GuaranteeTypeEnum;
  guaranteeMonths: number;
  otherGuaranteeType: string | null;
  payDay: number;
  coverMeterSystem: boolean;
  meterSystemAmount: number;
  clarkeCommissionNote: string;
  feedback: Feedback | null;
  note: string;
}

export interface Trader {
  id: string;
  name: string;
}

export interface ProposalTraderWithBid extends Trader {
  bid?: Bid;
}

export interface Proposal {
  id: string;
  unitsResume: UnitsResume;
  units: Unit[];
  createdAt: Date;
  deadline: Date;
  round: number;
  status: keyof typeof ProposalStatus;
  bids: Bid[];
  traders: ProposalTraderWithBid[];
  group: CommercialGroup;
  periods: number[];
  lowerFlexibility: number;
  upperFlexibility: number;
  productType: keyof typeof ProductTypeEnum;
  contractType: keyof typeof ContractTypeEnum;
  proposalType: keyof typeof ProposalTypeEnum;
  guaranteeType: keyof typeof GuaranteeTypeEnum;
  guaranteeMonths: number;
  otherGuarantee: string;
  note: string;
  supplyStartDate?: string | null;
  supplyEndDate?: string | null;
  commissionModality: keyof typeof CommissionModalityTypeEnum;
  commissionType?: keyof typeof CommissionTypeEnum;
  gmv: string | null;
  carbonDioxide: string | null;
  takeRate: string | null;
  commissionAnalysis?: CommissionAnalysis;
}

export interface CommercialGroup {
  id: string;
  name: string;
  legalName: string;
  docType: DocType;
  docNumber: string;
}

export interface CommissionAnalysis {
  id?: string;
  suggestedCommission: number;
  contractingModel?: keyof typeof CommissionModalityTypeEnum;
  commissionType?: keyof typeof CommissionTypeEnum;
}

export interface ProcessData {
  id: string;
  name: string;
  group: CommercialGroup;
  proposals: Proposal[];
}

export const EMPTY_PROCESS: ProcessData = {
  id: '',
  name: '',
  group: {
    id: '',
    name: '',
    legalName: '',
    docType: 'CNPJ',
    docNumber: '',
  },
  proposals: [
    {
      id: '',
      createdAt: new Date(),
      deadline: new Date(),
      round: NaN,
      status: 'ACTIVE',
      bids: [],
      traders: [],
      group: {
        id: '',
        name: '',
        legalName: '',
        docNumber: '',
        docType: 'CNPJ',
      },
      productType: 'AGREGGA',
      contractType: 'FIXED_PRICE',
      periods: [],
      lowerFlexibility: NaN,
      upperFlexibility: NaN,
      proposalType: 'INDICATIVE',
      guaranteeType: 'NO_GUARANTEE',
      guaranteeMonths: NaN,
      otherGuarantee: '',
      note: '',
      supplyStartDate: null,
      supplyEndDate: null,
      commissionModality: 'NO_PREFERENCE',
      commissionType: 'REALS_PER_MEGAWATT',
      units: [],
      unitsResume: {
        totalVolume: NaN,
        totalAmount: NaN,
        totalContractedDemandOffPeak: NaN,
        totalContractedDemandPeak: NaN,
      },
      gmv: null,
      carbonDioxide: null,
      takeRate: null,
    },
  ],
};
