import { BidFormData } from '@components/molecules/create-bid/types';
import { ParsedBid } from '@hooks/bids/get-data';
import { ProposalForBid } from '@hooks/proposals';
import { formatDate } from '@utils/text';
import { DeepPartial } from 'react-hook-form';

export const getContractDates = (units: ProposalForBid['units']): { start: Date; end: Date } => {
  const startDates: Date[] = [];
  const endDates: Date[] = [];
  units.forEach((unit) => {
    if (unit.supply.start) {
      startDates.push(unit.supply.start);
    }
    if (unit.supply.end) {
      endDates.push(unit.supply.end);
    }
  });
  return {
    start: startDates.sort((a, b) => a.getTime() - b.getTime())[0],
    end: endDates.sort((a, b) => b.getTime() - a.getTime())[0],
  };
};

export const getContractDuration = (units: ProposalForBid['units']): number => {
  if (units.length > 0) {
    const { start, end } = getContractDates(units);
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();

    return endYear - startYear + 1;
  }

  return 0;
};

export const buildEmptyAmountPerYearArray = (year: number, startingYear: number) => {
  return [...Array(year).keys()].map((_, index) => {
    return { year: startingYear + index, amount: NaN };
  });
};

type TraderType = 'RETAIL' | 'WHOLESALE' | 'NO_PREFERENCE';

// at propoasal the property is called commissionModality and can be seted as:
//   RETAILER | WHOLESALER |  NO_PREFERENCE
// at bid this property is called traderType and can be seted as:
//   RETAIL | WHOLESALE |  NO_PREFERENCE

const traderTypeIndexer: Record<string, TraderType> = {
  RETAILER: 'RETAIL',
  WHOLESALER: 'WHOLESALE',
  NO_PREFERENCE: 'NO_PREFERENCE',
};

export const translateProposalQueryIntoFormData = (
  proposal: ProposalForBid,
  trader: string,
): DeepPartial<BidFormData> => {
  const contractDuration = getContractDuration(proposal.units);

  const { start } = getContractDates(proposal.units);
  const prices: BidFormData['productAndPrices']['prices'] = {
    duration: contractDuration,
    values: buildEmptyAmountPerYearArray(contractDuration, start.getFullYear()),
  };

  return {
    productAndPrices: {
      contractType: proposal.contractType,
      prices,
    },
    commercialConditions: {
      proposalType: proposal.proposalType,
      lowerFlexibility: proposal.lowerFlexibility,
      upperFlexibility: proposal.upperFlexibility,
      traderType: traderTypeIndexer[proposal.commissionModality],
      coverCceeCost: 'NONE',
      coverCceeTaxes: 'NONE',
      coverMeterSystem: '',
      meterSystemAmount: NaN,
      bidDeadline: { date: '', time: '' },
      guaranteeType: proposal.guaranteeType,
      guaranteeMonths:
        isNaN(proposal.guaranteeMonths) || proposal.guaranteeMonths === 0
          ? ''
          : (`${proposal.guaranteeMonths}` as BidFormData['commercialConditions']['guaranteeMonths']),
      otherKindGuarantee: proposal.otherKindGuarantee,
      payDay: NaN,
      clarkeCommissionType: '',
      files: [],
    },
    trader,
  };
};

export const buildAmountPerYearArrayFromEconomy = (
  economy: ParsedBid['economy'],
  period: number,
  firstYear: number,
) => {
  const economyForPeriod = economy.find((entry) => entry.period === period);
  return economyForPeriod === undefined
    ? buildEmptyAmountPerYearArray(period, firstYear)
    : economyForPeriod.economyPerYear;
};

export const translateBidIntoFormData = (bid: ParsedBid): BidFormData => {
  const contractDuration = getContractDuration(bid.units);

  let firstYear: number;
  if (bid.units.length > 0) {
    const { start } = getContractDates(bid.units);
    firstYear = start.getFullYear();
  } else {
    const now = new Date();
    firstYear = now.getFullYear() + 1;
  }

  const prices: BidFormData['productAndPrices']['prices'] = {
    duration: contractDuration,
    values: buildAmountPerYearArrayFromEconomy(bid.economy, contractDuration, firstYear),
  };
  return {
    productAndPrices: {
      contractType: bid.contractType,
      prices,
    },
    commercialConditions: {
      lowerFlexibility: bid.lowerFlexibility,
      upperFlexibility: bid.upperFlexibility,
      proposalType: bid.proposalType,
      traderType: traderTypeIndexer[bid.commissionModality],
      coverCceeCost: bid.coverCceeCost,
      coverCceeTaxes: bid.coverCceeTaxes,
      coverMeterSystem: bid.coverMeterSystem ? 'YES' : 'NO',
      meterSystemAmount: bid.meterSystemAmount,
      bidDeadline: { date: formatDate(bid.deadline, 'YYYY-MM-DD'), time: formatDate(bid.deadline, 'HH:mm') },
      clarkeCommissionType: bid.clarkeCommissionNote,
      guaranteeType: bid.guaranteeType,
      guaranteeMonths: (isNaN(bid.guaranteeMonths)
        ? ''
        : bid.guaranteeMonths) as BidFormData['commercialConditions']['guaranteeMonths'],
      otherKindGuarantee: bid.otherKindGuarantee,
      payDay: bid.payDay,
      note: bid.note,
      files: [],
    },
    trader: bid.trader.id,
  };
};
