import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { Link } from 'react-router-dom';

import { CREATE_BID_PATH_SUFFIX, CREATE_FINAL_BID_PATH_SUFFIX, VIEW_BID_LIST_PATH } from '@routers/constants';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-labels';

import { UserType } from '@utils/constants/common';
import { formatCurrency, formatDate } from '@utils/text';

import UploadedFileDisplay from '@components/atoms/proposal-round/uploaded-file-display';

import { Bid, Proposal, BidStatus } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import {
  ContractTypeEnum,
  CoverCeeeCostEnum,
  CoverCeeeTaxesEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
} from '@utils/translators/proposal';

const BidEconomySection: React.FC<{ bid: Pick<Bid, 'contractType' | 'economy'> }> = ({ bid }) => {
  const isFixedPrice = bid.contractType === 'FIXED_PRICE';
  return (
    <>
      {bid.economy.map(({ economyPerYear }, index) => (
        <React.Fragment key={index}>
          <h2 className="font-bold text-paragraph-small">Preço da energia</h2>
          <div className="flex overflow-x-auto py-2 space-x-3 border-black sm:space-x-6 border-y">
            {economyPerYear.map(({ year, amount }, pearYearIndex) => (
              <div key={pearYearIndex} className="flex flex-col">
                <p className="text-paragraph-small text-neutral-50">{year}</p>
                <p className="text-paragraph-medium" data-cy={`price-${year}`}>
                  {isFixedPrice ? formatCurrency(amount) : `${amount}%`}
                </p>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

interface BidEconomyProposalContentsDisplayProps {
  bid: Pick<Bid, 'contractType' | 'periods' | 'economy'>;
}
export const BidEconomyProposalContentsDisplay: React.FC<BidEconomyProposalContentsDisplayProps> = ({ bid }) => {
  return (
    <div className="flex flex-col py-5 px-3 space-y-6 sm:px-5">
      <div className="flex flex-col space-y-2">
        <div className="grid grid-cols-2 gap-2 border-black border-y">
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Tipo de contrato</p>
            <p className="text-paragraph-medium" data-cy="contract-type">
              {ContractTypeEnum[bid.contractType]}
            </p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Duração do contrato</p>
            <p className="text-paragraph-medium" data-cy="contract-duration">
              {bid.periods.map((year) => `${year} anos`).join(', ')}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <BidEconomySection bid={bid} />
      </div>
    </div>
  );
};

interface BidCommercialConditionsContentsDisplayProps {
  bid: Pick<
    Bid,
    | 'proposalType'
    | 'retailService'
    | 'coverCceeCost'
    | 'coverCceeTaxes'
    | 'lowerFlexibility'
    | 'upperFlexibility'
    | 'coverMeterSystem'
    | 'meterSystemAmount'
    | 'deadline'
  >;
}
export const BidCommercialConditionsContentsDisplay: React.FC<BidCommercialConditionsContentsDisplayProps> = ({
  bid,
}) => {
  return (
    <div className="flex flex-col py-5 px-3 space-y-3 sm:px-5">
      <div className="flex flex-col space-y-2">
        <div className="grid grid-cols-1 gap-2 border-black lg:grid-cols-3 xl:grid-cols-4 border-y">
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Tipo de proposta</p>
            <p className="text-paragraph-medium" data-cy="proposal-type">
              {ProposalTypeEnum[bid.proposalType]}
            </p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Modalidade de contratação</p>
            <p className="text-paragraph-medium" data-cy="trader-type">
              {bid.retailService ? 'Varejista' : 'Atacadista'}
            </p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Cobertura dos encargos CCEE</p>
            <p className="text-paragraph-medium" data-cy="cover-ccee-taxes">
              {CoverCeeeTaxesEnum[bid.coverCceeTaxes]}
            </p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Cobertura da taxa de adesão da CCEE</p>
            <p className="text-paragraph-medium" data-cy="cover-ccee-costs">
              {CoverCeeeCostEnum[bid.coverCceeCost]}
            </p>
          </div>

          <div className="col-span-full border-t border-black"></div>

          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Flexibilidade inferior</p>
            <p className="text-paragraph-medium" data-cy="lower-flexibility">{`-${bid.lowerFlexibility}%`}</p>
          </div>

          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Flexibilidade superior</p>
            <p className="text-paragraph-medium" data-cy="upper-flexibility">{`+${bid.upperFlexibility}%`}</p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Cobertura da adequação SMF</p>
            <p className="text-paragraph-medium" data-cy="cover-meter-system">
              {bid.coverMeterSystem ? `Sim, ${formatCurrency(bid.meterSystemAmount)}` : 'Não'}
            </p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Validade da proposta</p>
            <p className="text-paragraph-medium" data-cy="bid-deadline">
              {isNaN(bid.deadline.getTime()) ? '-' : formatDate(bid.deadline, 'DD/MM/YYYY HH:mm')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface BidPaymentContentsDisplayProps {
  bid: Pick<Bid, 'guaranteeType' | 'payDay' | 'clarkeCommissionNote' | 'note' | 'documents'>;
}
export const BidPaymentContentsDisplay: React.FC<BidPaymentContentsDisplayProps> = ({ bid }) => {
  return (
    <div className="flex flex-col py-5 px-3 space-y-3 sm:px-5">
      <div className="flex flex-col space-y-2">
        <div className="grid grid-cols-1 gap-2 border-black lg:grid-cols-3 xl:grid-cols-4 border-y">
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Garantia</p>
            <p className="text-paragraph-medium">{GuaranteeTypeEnum[bid.guaranteeType]}</p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Dia do pagamento</p>
            <p className="text-paragraph-medium">{`${bid.payDay}º dia útil`}</p>
          </div>
          <div className="flex flex-col py-4">
            <p className="text-paragraph-small text-neutral-50">Comissão Clarke</p>
            <p className="text-paragraph-medium">{bid.clarkeCommissionNote || '-'}</p>
          </div>

          <div className="col-span-full border-t border-black"></div>

          <div className="flex flex-col col-span-full py-4">
            <p className="text-paragraph-small text-neutral-50">Observações</p>
            <p className="text-paragraph-medium">{bid.note || '-'}</p>
          </div>
        </div>

        {bid.documents.length > 0 && (
          <div className="flex flex-col col-span-full space-y-2">
            <p className="text-paragraph-small text-neutral-50">Documentos</p>
            {bid.documents.map((document, index) => (
              <div className="flex flex-col space-y-2" key={`${document.entityId}-${index}`}>
                <UploadedFileDisplay file={document} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const bidStatusMessageMapper = new Map<keyof typeof BidStatus, { title: string; subtitle: string }>();
bidStatusMessageMapper.set('STAGE_WINNER', {
  title: 'Parabéns! Você foi selecionado para a próxima rodada :)',
  subtitle:
    'Mostramos todas as propostas para o cliente e você foi uma das comercializadoras escolhidas para a próxima rodada! Dê uma olhadinha no feedback sobre sua performance na rodada atual e cadastre sua proposta para a rodada seguinte!',
});
bidStatusMessageMapper.set('STAGE_REFUSED', {
  title: 'Infelizmente não foi dessa vez :(',
  subtitle:
    'Comparamos todas as propostas dentro dos mesmos parâmetros e o cliente infelizmente não escolheu você para a próxima rodada',
});
bidStatusMessageMapper.set('FINAL_WINNER', {
  title: 'Parabéns! Você foi escolhido para a rodada Final.',
  subtitle:
    'Depois de diversas etapas e comparações, a sua proposta se destacou e o cliente a escolheu. Dê uma olhada no feedback e submeta a proposta final assinada.',
});
bidStatusMessageMapper.set('FINAL_REFUSED', {
  title: 'Infelizmente não foi dessa vez :(',
  subtitle:
    'Comparamos todas as propostas dentro dos mesmos parâmetros e o cliente infelizmente não escolheu você para a assinatura. Confira o feedback para aprimorar suas propostas para os próximos clientes.',
});

export const StatusMessageForNonFinalBidDisplay: React.FC<{ bid: Bid }> = ({ bid }) => {
  const displays = bidStatusMessageMapper.get(bid.status);
  if (!displays) return null;

  const { title, subtitle } = displays;

  return (
    <div className="flex flex-col space-y-6">
      <p className="text-neutral-50">{bid.feedback === null ? '' : formatDate(bid.feedback.createdAt).toUpperCase()}</p>
      <p className="w-2/3 font-semibold text-heading-2xlarge">{title}</p>
      <p className="w-2/3">{subtitle}</p>
    </div>
  );
};

interface TraderCreatedNonFinalBidDisplayMessageProps {
  proposal: Proposal;
}
export const TraderCreatedNonFinalBidDisplayMessage: React.FC<TraderCreatedNonFinalBidDisplayMessageProps> = ({
  proposal,
}) => {
  const bidsSortedFromOldestToNewest = cloneDeep(proposal.bids).sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  );
  const hasMoreThanOneBid = bidsSortedFromOldestToNewest.length > 1;
  const bid = hasMoreThanOneBid ? (bidsSortedFromOldestToNewest.at(-1) as Bid) : bidsSortedFromOldestToNewest[0];

  return (
    <div className="py-8">
      <p className="text-neutral-50">{formatDate(bid.createdAt).toUpperCase()}</p>
      <p className="text-heading-2xlarge">{hasMoreThanOneBid ? 'Propostas enviadas' : 'Proposta enviada'}</p>
      <p className="mt-4 w-3/5 text-paragraph-medium">
        Agora é esperar o retorno do cliente! Abaixo você pode conferir os detalhes da proposta que você enviou.
      </p>
    </div>
  );
};

interface TraderCreatedFinalBidDisplayMessageProps {
  proposal: Proposal;
}
export const TraderCreatedFinalBidDisplayMessage: React.FC<TraderCreatedFinalBidDisplayMessageProps> = ({
  proposal,
}) => {
  const bidsSortedFromOldestToNewest = cloneDeep(proposal.bids).sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  );
  const hasMoreThanOneBid = bidsSortedFromOldestToNewest.length > 1;
  const oldestBid = hasMoreThanOneBid ? (bidsSortedFromOldestToNewest.at(-1) as Bid) : bidsSortedFromOldestToNewest[0];

  return (
    <div className="py-8">
      <p className="text-neutral-50">{formatDate(oldestBid.createdAt).toUpperCase()}</p>
      <p className="text-heading-2xlarge">{hasMoreThanOneBid ? 'Propostas enviadas' : 'Proposta enviada'}</p>
      <p className="mt-4 w-3/5 text-paragraph-medium">
        Agora é esperar a assinatura do cliente! Sabemos que nem sempre o cliente consegue assinar dentro do prazo,
        quando ele for expirado, pediremos uma nova proposta atualizada. Abaixo você pode conferir os detalhes das suas
        propostas.
      </p>
    </div>
  );
};

const FIRST_ROUND_BID_ALLOWED_MESSAGE =
  'Chegou solicitação nova! Confira as informações do cliente, as condições do contrato e a comissão da Clarke para realizar a sua Proposta. Em seguida, cadastre-a aqui para participar da primeira rodada de orçamento.';
const NTH_ROUND_BID_ALLOWED_MESSAGE =
  'Parabéns! Você foi selecionada para participar da próxima rodada. Comparamos todas as propostas dentro dos mesmos parâmetros e o cliente escolheu você! Confira as informações do cliente e cadastre sua proposta!';
export const TraderCanCreateNonFinalBidDisplay: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  const isThisProposalInFirstRound = proposal.round === 1;
  return (
    <div className="flex flex-col space-y-14">
      <div className="flex flex-col space-y-4 w-full xl:w-3/5">
        <h3 className="text-heading-2xlarge text-medium">Você ainda não cadastrou sua proposta!</h3>
        <p className="text-paragraph-medium">
          {isThisProposalInFirstRound ? FIRST_ROUND_BID_ALLOWED_MESSAGE : NTH_ROUND_BID_ALLOWED_MESSAGE}
        </p>
      </div>
      <Link
        className="py-2 px-4 w-64 font-medium text-center rounded-full border border-black sm:w-1/2 xl:w-1/4 2xl:w-1/6 bg-primary-60"
        role="button"
        to={`${VIEW_BID_LIST_PATH}/${proposal.id}/${CREATE_BID_PATH_SUFFIX}`}
        onClick={() =>
          eventTracker.trackEvent(eventLabels.DETAILED_PROPOSAL_PAGE_ADD_BID, {
            userType: UserType.trader,
          })
        }
      >
        Cadastrar proposta
      </Link>
    </div>
  );
};

const FINAL_ROUND_BID_ALLOWED_MESSAGE =
  'O cliente te escolheu para a Rodada Final! Esse é o momento de assinatura, envie sua melhor proposta final para buscarmos o fechamento com o cliente.';
export const TraderCanCreateFinalBidDisplay: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-col space-y-4 w-full xl:w-3/5">
        <h3 className="text-heading-2xlarge text-medium">Envie a proposta final!</h3>
        <p className="text-paragraph-medium">{FINAL_ROUND_BID_ALLOWED_MESSAGE}</p>
      </div>
      <Link
        className="py-2 px-4 w-64 font-medium text-center rounded-full border border-black sm:w-1/2 xl:w-1/4 2xl:w-1/6 bg-primary-60"
        role="button"
        data-cy="create-final-bid-button"
        to={`${VIEW_BID_LIST_PATH}/${proposal.id}/${CREATE_FINAL_BID_PATH_SUFFIX}`}
      >
        Enviar proposta final
      </Link>
    </div>
  );
};

export const TraderCannotCreateBidDisplay: React.FC = () => {
  return (
    <div className="w-full xl:w-3/5">
      <h3 className="text-heading-2xlarge text-medium">Você não cadastrou sua Proposta :(</h3>
      <div className="mt-4 mb-14 text-paragraph-medium">A proposta já foi expirada ou finalizada.</div>
    </div>
  );
};
